import PageViewBarChartCard from "../components/cards/PageViewBarChartCard";
import SystemMobileTabletLayout from "../components/layouts/SystemMobileTabletLayout";
import ChartsDesktopLayout from "../components/layouts/chartsScreen/ChartsDesktopLayout";

function ChartsScreen() {
    // Used in index.js as the app entry point for default route

    // BUILD
    return (
        <div className="overflow-auto bg-backgroundLight3 w-full h-[100svh] flex flex-col">
            <SystemMobileTabletLayout />
            <ChartsDesktopLayout>
                <PageViewBarChartCard />
            </ChartsDesktopLayout>
        </div>
    );

}

// EXPORT
export default ChartsScreen