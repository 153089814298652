
// Used to provide fake and static data for tests or debugging

// PROPERTIES
export const kFakeToken = {
    "code": 200,
    "user": "administrateur@ummia.fr",
    "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MzA5MDI1MDgsImV4cCI6MTczMDkwNjEwOCwicm9sZXMiOlsiUk9MRV9TVVBFUl9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6ImFkbWluaXN0cmF0ZXVyQHVtbWlhLmZyIn0.EvPY5m12BMhdmFjq4XGdHT7t7NNdA8uC53_RUqgbhsKIz-S3O2bvFN_fMaNADr_5ngmXddQokOsl0OwtVASwsqs5TAEPIgd1hsBR8wsrXz_mATFMVap_ANR8Vuv9EBwFpi4B4mDTdpxj_gQmd3sBVO7Y0YKcxGC6bpTo92OVpRRkSkUGr41K_VBOzUc46E-J6PE9RultHiErc1zbu4xD02OZndkCdFmU2rCKxZcmXAzkTlNmR-H4y1LYI7quTcxzhPIQTHMONlCF7aJNFrxpZOv5cZD-4H_e0YS19ueIIXTKXbaBwoQ9glrhIItIrn75z9KOdTu5ZyjmZ913tAxlNQ",
    "refreshToken": "8aa6bdbe3a7449184a28d46d4a48d82825f2236c87b2ba239ba11deb0ba69ef65b6fe0beb59004525dc061af0ba9cdb4833f4131f884c5983ba5a8f9a8b4254a",
    "refreshTokenExpiration": 1733494508
}
