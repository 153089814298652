
// Used through the app to list dates related actions
// to update values in the store

// METHODS
// ---- Update start date (YYYY-MM-DD) => YYYY-MM-DD
export const updateStartDate = (newStartDate) => ({
    type: 'updateStartDate', // Action type referenced in store
    payload: newStartDate,   // New value for the state to update
});

// ---- Update end date (YYYY-MM-DD) => YYYY-MM-DD
export const updateEndDate = (newEndDate) => ({
    type: 'updateEndDate', // Action type referenced in store
    payload: newEndDate,   // New value for the state to update
});