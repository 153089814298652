import {Switch} from "@mui/material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import {kPreviewSwitchStyle} from "../../constants/styles";

function PlatformSwitchButton({isMobileSelected, handlePlatformChange}) {
    // Used in AppSideBar to show selectedPlatform between
    // mobile and desktop, displaying related statistics

    // BUILD
    return (
        <div className={"w-full flex justify-between items-center mt-12 px-16"}>
            <span className={`font-medium text-[14px] ${isMobileSelected ? "text-greyLight9" : "text-greyLight7"}`}>
                {"Mobile"}
            </span>
            <PhoneIphoneIcon />
            <Switch value={isMobileSelected}
                    onChange={() => handlePlatformChange()}
                    sx={kPreviewSwitchStyle} />
            <PersonalVideoIcon />
            <span className={`pl-4 font-medium text-[14px] ${!isMobileSelected ? "text-greyLight9" : "text-greyLight7"}`}>
                {"Web"}
            </span>
        </div>
    );
}

// EXPORT
export default PlatformSwitchButton