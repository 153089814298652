
function VisitsTotalText({totalVisits}) {
    // Used in PageViewBarChartCard to display graph total
    // amount

    // BUILD
    return (
        <div className={"flex justify-end items-center space-x-4"}>
            <span className={"font-semibold text-[16px] text-greyLight9"}>
                {"Total :"}
            </span>
            <span className={"font-semibold text-[18px] text-white"}>
                {totalVisits ?? "N/A"}
            </span>
        </div>
    );
}

// EXPORT
export default VisitsTotalText