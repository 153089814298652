import {BarChart} from "@mui/x-charts";
import {CircularProgress} from "@mui/material";
import {isArrayNullUndefinedOrEmpty} from "../../services/Validator";

function PageBarChart({labels, barData, isLoading, hasError}) {
    // Used in PageViewBarChartCard to display bar chart about visits

    // BUILD
    if (hasError)
        return <div className={"animate-appear h-[280px] w-[600px] min-w-[600px] flex justify-center items-center text-greyLight11"}>
            Error
        </div>
    if (isLoading)
        return <div className={"animate-appear h-[280px] w-[600px] min-w-[600px] flex justify-center items-center"}>
            <CircularProgress size={"56px"}
                              style={{'color' : 'white'}} />
        </div>
    if (isArrayNullUndefinedOrEmpty(labels))
        return <div className={"animate-appear h-[280px] w-[600px] min-w-[600px] flex justify-center items-center text-greyLight11"}>
            {"Aucune données à afficher sur les dates sélectionnées"}
        </div>
    return (
        <div className={"animate-appear w-[600px] min-w-[600px]"}>
            <BarChart
                sx={{
                    // Vertical axis
                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel":{
                        fill:"#DBE2EC"
                    },
                    "& .MuiChartsAxis-left .MuiChartsAxis-tick":{
                        stroke:"#DBE2EC"
                    },
                    "& .MuiChartsAxis-left .MuiChartsAxis-label":{
                        fill:"#DBE2EC"
                    },
                    "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                        stroke:"#DBE2EC",
                        strokeWidth:1
                    },
                    // Horizontal axis
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel":{
                        strokeWidth:"0.5",
                        fill:"#DBE2EC"
                    },
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                        stroke:"#DBE2EC",
                        strokeWidth:1
                    },
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tick":{
                        stroke:"#DBE2EC"
                    },
                }}
                borderRadius={8}
                width={600}
                height={280}
                xAxis={[{ data: labels, scaleType: 'band' }]}
                series={[
                    { data: barData.mobile, label: 'Mobile', id: 'mobile', stack: 'total', color: '#F594CA' },
                    { data: barData.tablet, label: 'Tablette', id: 'tablet', stack: 'total', color: '#F2AF55' },
                    { data: barData.desktop, label: 'Ordinateur', id: 'desktop', stack: 'total', color: '#99EF96' },
                ]}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'top', horizontal: 'middle' },
                        padding: 0,
                        labelStyle: {
                            fontSize: 14,
                            fill: '#DBE2EC',
                        },
                    }
                }}
            />
        </div>
    );
}

// EXPORT
export default PageBarChart