import {useState} from "react";
import {Link} from "react-router-dom";
import {kButtonStyles} from "../../constants/styles";


function SystemCustomButton({
    id,
    label,
    icon,
    onButtonClick,
    onButtonMouseEnter,
    onButtonMouseLeave,
    isFullWidth,
    buttonStyle,
    sizeStyle,
    isLoading,
    loaderComponent,
    isDisabled,
    extraClass,
    internalLink,
    externalLink}) {
    // Used through the app to enable CTA

    // METHODS
    // ---- Compute button width
    function computeButtonWidth() {
        return isFullWidth ? "w-full" : ""
    }

    // ---- Render button content based on props
    function renderButtonContent() {
        if (isLoading) {
            return <div className="min-h-[16px] max-h-[16px] flex justify-center items-center">
                        {loaderComponent ?? null}
                   </div>
        } else {
            return <div className="whitespace-nowrap flex items-center justify-center leading-base">
                        {icon ? <i className="material-icons">{icon}</i> : null}
                        {label ? <span>{label}</span> : null}
                   </div>
        }
    }

    // ---- Render vanilla button based on props
    function renderVanillaButton() {
        return <div className={computeButtonWidth() + " cursor-pointer"}>
                <button
                    id={id}
                    type="button"
                    className={buttonStyle + " "
                        + sizeStyle + " "
                        + extraClass}
                    onClick={!isDisabled && onButtonClick ? () => onButtonClick() : null}
                    onMouseEnter={() => onButtonMouseEnter()}
                    onMouseLeave={() => onButtonMouseLeave()}
                    disabled={isDisabled || isLoading}>
                        {renderButtonContent()}
                </button>
        </div>
    }

    // BUILD
    if (externalLink) {
        return <a href={externalLink}>
                    {renderVanillaButton()}
               </a>
    } else if (internalLink) {
      return <Link className={computeButtonWidth() + " cursor-pointer"} to={internalLink}>
                {renderVanillaButton()}
             </Link>
    } else {
        return renderVanillaButton()
    }
}

// ---- Compute button style
export function computeButtonStyle(isDisabled, isHovered, defaultStyle, hoveredStyle, disabledStyle) {
    if (isDisabled) {
        return disabledStyle
    } else {
        return isHovered ? hoveredStyle : defaultStyle
    }
}

// EXPORT BUTTON VARIANTS
// ---- Export primary style button
export function SystemPrimaryCustomButton(props)
{
    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <SystemCustomButton
            {...props}
            buttonStyle={computeButtonStyle(props.isDisabled, isHovered, kButtonStyles.primaryButtonStyle, kButtonStyles.primaryHoveredButtonStyle, kButtonStyles.primaryDisabledButtonStyle)}
            onButtonMouseEnter={() => setIsHovered(true)}
            onButtonMouseLeave={() => setIsHovered(false)}/>
    );
}

// ---- Export secondary style button
export function SystemSecondaryCustomButton(props)
{
    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <SystemCustomButton
            {...props}
            buttonStyle={isHovered ? kButtonStyles.secondaryHoveredButtonStyle : kButtonStyles.secondaryButtonStyle}
            onButtonMouseEnter={() => setIsHovered(true)}
            onButtonMouseLeave={() => setIsHovered(false)}/>
    );
}

// ---- Export error style button
export function SystemErrorCustomButton(props)
{
    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <SystemCustomButton
            {...props}
            buttonStyle={isHovered ? kButtonStyles.errorButtonStyle : kButtonStyles.errorHoveredButtonStyle}
            onButtonMouseEnter={() => setIsHovered(true)}
            onButtonMouseLeave={() => setIsHovered(false)}/>
    );
}