import {useSelector} from "react-redux";
import SystemCustomDivider from "../dividers/SystemCustomDivider";
import {kButtonStyles} from "../../constants/styles";
import {SystemSecondaryCustomButton} from "../buttons/SystemCustomButton";

function SideBarUserCard({logOut}) {
    // Used in AppSideBar to display current connected user
    // with logout button

    // PROPERTIES
    const userName = useSelector(state => state.userName);

    // BUILD
    return (
        <div className={"w-full flex flex-col mt-12"}>
            <SystemCustomDivider />
            <div className={"flex flex-col space-y-8 justify-start items-start px-16 mt-12"}>
                <span className={"font-medium text-greyLight7 text-[14px] leading-none"}>
                    {"Bienvenue"}
                </span>
                <span className={"font-medium text-greyLight9 text-[14px] leading-none"}>
                    {userName ?? "No authenticated user"}
                </span>
                <SystemSecondaryCustomButton
                    label={"Se déconnecter"}
                    extraClass={"mt-[8px]"}
                    isDisabled={false}
                    isLoading={false}
                    isFullWidth={true}
                    onButtonClick={() => logOut()}
                    sizeStyle={kButtonStyles.mediumButtonStyle} />
            </div>
        </div>
    );
}

// EXPORT
export default SideBarUserCard