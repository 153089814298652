import axios from 'axios';

// Http request methods used for calls to the api

// ---- Export Http verbs
export const Methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
}

// ---- Export makeRequest method
export const makeRequest = (
    url,
    type,
    params,
    token = null
) => {
    return new Promise((resolve, reject) => {
        // PREPARE
        let requestConfig = {
            url: url,
            method: type,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            responseType: 'json',
        }
        // If token is provided, add it in headers
        if (token) {
            requestConfig.headers['Authorization'] = `Bearer ${token}`;
        }

        // Handle PUT & POST requests
        if (type !== Methods.DELETE && type !== Methods.GET) {
            requestConfig.data = params
        }
        // PERFORM
        axios
            .request(requestConfig)
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                if (error.response) {
                    reject(error.response.data)
                    return
                }
                reject(error)
            })
    })
}

