
function ErrorScreen() {
    // Used in index.js to display error when request page is not found

    // BUILD
    return (
        <div className={"overflow-auto w-full h-screen flex flex-col justify-start items-center space-y-32 p-16 sm:p-32 lg:p-48"}>
            <span className={""}>
                Error
            </span>
            <span className={""}>
                Page not found
            </span>
        </div>
    );
}

// EXPORT
export default ErrorScreen