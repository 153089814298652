
// PROPERTIES
export const kTokenKey = "token"
export const kRefreshTokenKey = "refreshToken"

// METHODS
// ---- Get json in local storage from key
export const getLocalItemAsJsonFromKey = (key) => {
    return JSON.parse(localStorage.getItem(key))
}

// ---- Set local item from key with json object
export const setLocalItemFromKey = (key, jsonObject) => {
    localStorage.setItem(key, JSON.stringify(jsonObject))
}