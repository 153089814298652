

// METHODS
// ---- Check if var is null or undefined
export const isNullOrUndefined = (value) => {
    return value === undefined || value === null
}

export const isNonEmptyString = (value) => {
    return typeof value === 'string' && value.trim() !== '';
}

// ---- Check if array is empty
export const isArrayEmpty = (array) => {
    return Array.isArray(array) && !array.length
}

// ---- Check if array is null, undefined or empty
export const isArrayNullUndefinedOrEmpty = (array) => {
    if (array === undefined || array === null) {
        return true
    } else {
        return Array.isArray(array) && !array.length
    }
}

// ---- Check password requirements
// export const checkPasswordRequirements = (value) => {
//     if (value !== '')
//         return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,255}$/.test(value)
//     else return false
// }

// ---- Validators
export const validator = {
    // notNull: (value) => (value != null ? undefined : 'Ce champ doit être renseigné'),
    minLength: (min) => (value) =>
        value.length >= min ? undefined : `${min} caractères minimum`,
    maxLength: (max) => (value) =>
        value.length <= max ? undefined : `${max} caractères maximum`,
    // isFamilyName: (value) => (
    //     RegExp(rgxIsName).test(value) ? undefined : "Ce champ doit être un nom"
    // ),
    // isFirstName: (value) => (
    //     RegExp(rgxIsName).test(value) ? undefined : "Ce champ doit être un prénom"
    // ),
    // valueBetween: (first, second) => (value) =>
    //     value > first && value < second
    //         ? `Ne doit pas être compris en ${first} et ${second}`
    //         : undefined,
    // mustBeDate: (value) =>
    //     RegExp(rgxIsDate).test(value)
    //         ? undefined
    //         : 'Format de date invalide (JJ/MM/AAAA)',
    // exactLength: (length) => (value) =>
    //     value.length === length
    //         ? undefined
    //         : `Ce champ doit faire ${length} caractères`,
    // isSame: (compare) => (value) =>
    //     value === compare ? undefined : 'Les mots de passe ne sont pas identiques',
    // validatePassword: (value) =>
    //     checkPasswordRequirements(value)
    //         ? undefined
    //         : 'Votre mot de passe ne respecte pas les conditions nécessaires',
    // composeValidators:
    //     (...validators) =>
    //         (value, allValues) =>
    //             validators.reduce(
    //                 (error, validator) => error || validator(value, allValues),
    //                 undefined
    //             ),
}