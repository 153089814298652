import {isNonEmptyString, isNullOrUndefined} from "../services/Validator";


// METHODS
// ---- Get today date without time : YYYY-MM-DD
export function todayDateString() {
    const today = new Date();
    return today.toISOString().split('T')[0]
}

// ---- Get day after input dateString => YYYY-MM-DD
export function addOneDayToDateString(dateString) {
    if (isNullOrUndefined(dateString) || isNonEmptyString(dateString))
        return null
    const date = new Date(dateString); // Créer une nouvelle instance de Date à partir de la chaîne
    date.setDate(date.getDate() + 1); // Ajouter un jour
    return date.toISOString().split('T')[0]; // Retourner la date au format YYYY-MM-DD
}

// ---- Get number of days before given dateString => YYYY-MM-DD
export function subtractDaysToDayString(dateString, days) {
    if (isNullOrUndefined(dateString) || isNullOrUndefined(days) || !isNonEmptyString(dateString))
        return null
    const date = new Date(dateString); // Créer une nouvelle instance de Date à partir de la chaîne
    date.setDate(date.getDate() - days); // Soustraire le nombre de jours
    return date.toISOString().split('T')[0]; // Retourner la date au format YYYY-MM-DD
}

// ---- Convert dateString into DateTime object
export function convertDateStringIntoDateTime(dateString) {
    return new Date(dateString)
}