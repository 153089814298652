// Api related configuration

// ---- Api related configuration values
export const api = {
    initial_domain: process.env.REACT_APP_API_URL,
    domain: process.env.REACT_APP_API_URL + "/",
    routes: {
        // PageViewMetric
        getFilteredPageViewMetric: "metrics/pageViewMetric/filter",
        // Auth
        logUser: "auth/jwt_login",
        refreshToken: "auth/refresh_token"
    }
}