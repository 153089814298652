import HomeLogoText from "../images/HomeLogoText";
import {useDispatch, useSelector} from "react-redux";
import {updateEndDate, updateStartDate} from "../../actions/dates";
import {addOneDayToDateString, subtractDaysToDayString, todayDateString} from "../../helpers/DateHelper";
import SideBarUserCard from "../others/SideBarUserCard";
import SystemCustomDivider from "../dividers/SystemCustomDivider";
import PlatformSwitchButton from "../buttons/PlatformSwitchButton";
import {Platform} from "../../enum/Platform";
import {updateSelectedPlatform} from "../../actions/platform";
import {setLocalItemFromKey, kTokenKey} from "../../services/LocalStorage";
import {useNavigate} from "react-router";
import {routes} from "../../config/routes";
import SideBarDateForm from "../forms/SideBarDateForm";

function AppSideBar() {
    // Used in ChartsScreen to display fixed Side bar for
    // parameters and navigation

    // PROPERTIES
    // ---- Navigation
    const navigate = useNavigate();
    // ---- Store
    const dispatch = useDispatch();
    // ---- Functional
    const startDate = useSelector(state => state.startDate);
    const startDateMin = "2024-08-15"
    const startDateMax = subtractDaysToDayString(todayDateString(), 1)
    const endDate = useSelector(state => state.endDate);
    const endDateMin = addOneDayToDateString(startDate) ?? ''
    const endDateMax = todayDateString()
    const selectedPlatform = useSelector(state => state.selectedPlatform)

    // BUILD
    return (
        <aside className="bg-backgroundLight5 border-r-1 border-greyLight5 w-[220px] flex-shrink-0">
            <div className="h-full w-full py-16 font-medium text-greyLight11 flex flex-col justify-between">
                <div className={"flex flex-col justify-start items-start"}>
                    <HomeLogoText />
                    <SystemCustomDivider />
                    <PlatformSwitchButton isMobileSelected={selectedPlatform === Platform.MOBILE}
                                          handlePlatformChange={handlePlatformChange} />
                    <SideBarDateForm startDate={startDate}
                                     startDateMin={startDateMin}
                                     startDateMax={startDateMax}
                                     handleStartDateChange={handleStartDateChange}
                                     endDate={endDate}
                                     endDateMin={endDateMin}
                                     endDateMax={endDateMax}
                                     handleEndDateChange={handleEndDateChange} />
                </div>
                <SideBarUserCard logOut={logOut}/>
            </div>
        </aside>
    );

    // METHODS
    // ---- Handle start date change
    function handleStartDateChange(event) {
        dispatch(updateStartDate(event.target.value))
    }

    // ---- Handle end date change
    function handleEndDateChange(event) {
        dispatch(updateEndDate(event.target.value))
    }

    // ---- Handle platform change
    function handlePlatformChange() {
        dispatch(updateSelectedPlatform(!selectedPlatform))
    }

    // ---- Logout current user
    function logOut() {
        setLocalItemFromKey(kTokenKey, null)
        navigate(routes.authentication)
    }
}

// EXPORT
export default AppSideBar