import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';

function SystemMobileTabletLayout() {
    // Used in screens to warn user about application
    // not available for small screens

    // BUILD
    return (
        <div className={"overflow-auto w-full h-full flex flex-col md:hidden items-center justify-center py-32 px-48"}>
            <PhotoSizeSelectSmallIcon sx={{ fontSize: 32, color: "#FFFFFF" }} />
            <span className={"font-medium text-white text-[16px] text-center mt-12"}>
                {"Cet affichage n'est pas accessible sur petit écran"}
            </span>
            <span className={"font-medium text-greyLight9 text-[14px] text-center mt-4"}>
                {"Veuillez passer sur un écran plus large"}
            </span>
        </div>
    );
}

// EXPORT
export default SystemMobileTabletLayout