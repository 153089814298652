import {useEffect, useState} from "react";
import {get_landing_page_view_metrics} from "../../api/pageViewMetric";
import {EventName} from "../../enum/PageViewMetric";
import BarChartParameters from "../others/BarChartParameters";
import PageBarChart from "../charts/PageBarChart";
import {get_nested} from "../../services/JsonHandler";
import {useSelector} from "react-redux";
import {convertDateStringIntoDateTime} from "../../helpers/DateHelper";
import {isNullOrUndefined} from "../../services/Validator";
import VisitsTotalText from "../texts/VisitsTotalText";
import {getLocalItemAsJsonFromKey, kTokenKey} from "../../services/LocalStorage";

function PageViewBarChartCard() {
    // Used in ChartsScreen to display Page bar chart

    // PROPERTIES
    // ---- Meta
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    // ---- Functional
    const [eventName, setEventName] = useState(EventName.LANDING)
    const [labels, setLabels] = useState([])
    const [barData, setBarData] = useState(null)
    const [totalVisits, setTotalVisits] = useState(null)
    const startDate = useSelector(state => state.startDate);
    const endDate = useSelector(state => state.endDate);

    // HOOKS
    // ---- At rendering : if local flash is in storage, set flash variables
    useEffect(() => {
        getPageViewMetric(eventName)
    }, [eventName, startDate, endDate]);

    // BUILD
    if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate))
        return null
    return (
        <div className={"w-[800px] min-w-[800px] bg-backgroundLight5 h-[348px] min-h-[348px] shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] flex flex-col justify-start items-start rounded-[16px]"}>
            <div className={'w-full flex justify-between items-center px-24 mt-12'}>
                <span className={"font-semibold text-[16px] text-greyLight9 "}>
                    {"Nombre de visiteurs"}
                </span>
                <VisitsTotalText totalVisits={totalVisits} />
            </div>
            <div className={"w-full bg-greyLight9 h-[1px] min-h-[1px] mt-12"}></div>
            <div className={"flex justify-start items-center mt-16"}>
                <PageBarChart labels={labels}
                              barData={barData}
                              isLoading={isLoading}
                              hasError={hasError} />
                <BarChartParameters eventName={eventName}
                                    onEventNamePressed={onEventNamePressed} />
            </div>
        </div>
    );

    // METHODS
    // ---- Set eventName
    function onEventNamePressed(pressedEventName) {
        // Defensive measure
        if (!pressedEventName || (pressedEventName === eventName))
            return
        // Execute
        setEventName(pressedEventName)
        getPageViewMetric(pressedEventName)
    }

    // ---- Get page view metric
    function getPageViewMetric(eventName) {
        const token = getLocalItemAsJsonFromKey(kTokenKey);
        if (!eventName || !token)
            return
        // Reset state
        setIsLoading(true)
        setHasError(false)
        // Prepare
        let data = {
            "eventName": eventName.event,
            "startDate": convertDateStringIntoDateTime(startDate),
            "endDate": convertDateStringIntoDateTime(endDate)
        }
        // Perform
        get_landing_page_view_metrics(data, token)
            .then((res) => {
                // ---- Format labels
                const days = res.map((event) => {
                    const day = get_nested(['eventDay'], event, null)
                    if (day != null) {
                        const sliced = day.slice(5)
                        const [month, da] = sliced.split("-");
                        return `${da}/${month}`;
                    } else {
                        return "null"
                    }
                })
                // ---- Format data
                const mobileData = res.map((event) => get_nested(['deviceDetail', 'mobile'], event, 0))
                const tabletData = res.map((event) => get_nested(['deviceDetail', 'tablet'], event, 0))
                const desktopData = res.map((event) => get_nested(['deviceDetail', 'desktop'], event, 0))
                const deviceData = {
                    "mobile": mobileData,
                    "tablet": tabletData,
                    "desktop": desktopData
                }
                // ---- Format total
                const mobileTotal = mobileData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                const tabletTotal = tabletData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                const desktopTotal = desktopData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                // ---- Set state
                setLabels(days)
                setBarData(deviceData)
                setTotalVisits(mobileTotal + tabletTotal + desktopTotal)
            }).catch((err) => {
            console.error(err)
            setHasError(true)
        }).finally(() => {
            setIsLoading(false)
        })
    }

}

// EXPORT
export default PageViewBarChartCard