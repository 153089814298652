import {api} from "./routes";
import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";

// List of methods triggering Authentication related api calls

// METHODS
// ---- Log user based on username, password json object
export function log_user(data) {
    const url = api.domain + generatePath(api.routes.logUser)
    return makeRequest(url, Methods.POST, data)
}

// ---- Refresh token with refreshToken value provided as input
export function refresh_token(data) {
    const url = api.domain + generatePath(api.routes.refreshToken)
    return makeRequest(url, Methods.POST, data)
}