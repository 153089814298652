import {setLocalItemFromKey, kTokenKey} from "../../services/LocalStorage";

function HomeLogoText() {
    // Used through the app to display flash logo image in desktop
    // mode which is the text version of flash logo

    // BUILD
    return (
        <div className={`animate-appear cursor-pointer h-[24px] min-h-[24px] max-h-[24px] px-16 mb-4`}
             onClick={() => setLocalItemFromKey(kTokenKey, null)}>
            <img className={"h-full"}
                 src={'/images/dashboardLogoText.png'}
                 alt="Logo" />
        </div>
    );
}

// EXPORT
export default HomeLogoText