
function SystemCustomDivider() {
    // Used through the app to display a divider

    // BUILD
    return (
        <div className={"w-full bg-greyLight5 h-[1px] min-h-[1px] mt-12"}></div>
    );
}

// EXPORT
export default SystemCustomDivider