// PageViewMetric entity related enums

// ---- Event name
export const EventName = {
    LANDING: {
        name: 'Visite de la landing',
        event: 'landing_visit'
    },
    FLASH_LAUNCH: {
        name: 'Lancement flash',
        event: 'flash_launch'
    },
    FLASH_RESULTS: {
        name: 'Écran de résultats',
        event: 'flash_results'
    },
    FLASH_CORRECTION: {
        name : 'Consultation de correction',
        event : 'flash_correction'
    }
}