import {CircularProgress} from "@mui/material";

function SystemScreenLoader() {
    // Used through the app to display a full screen centered loader

    // BUILD
    return (
        <div className={"w-full h-screen flex items-center justify-center"}>
            <div className={'animate-appear'}>
                <CircularProgress size={"56px"} style={{'color' : 'white'}} />
            </div>
        </div>
    );
}

// EXPORT
export default SystemScreenLoader